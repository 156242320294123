<template>
  <div>
    <div style="width: 100%; padding: 8px;">
      请选择土类:
      <select v-model="selectClass">
        <option
          v-for="item in classArr"
          :key="item.group_id"
          :value="item.group_id"
        >
          {{ item.group_name }}
        </option>
      </select>
      请选择亚类:
      <select v-model="selectSubClass">
        <option
          v-for="item in $store.state.subClassList"
          :key="item.subgroup_id"
          :value="item.subgroup_id"
        >
          {{ item.subgroup_gb_name }}
        </option>
      </select>
    </div>
    <!--
        列表展示
    -->
    <div class="table-responsive">
      <table
        class="table table-bordered"
        v-if="$store.state.speciesList.length"
      >
        <thead>
          <tr class="th">
            <th>土种名称</th>
            <th>描述</th>
            <th>分布和地形地貌</th>
            <th>面积（公顷）</th>
            <th>面积（万亩）</th>
            <th>母质</th>
            <th>剖面构型</th>
            <th>有效土体深度</th>
            <th>主要性状</th>
            <th>土壤障碍因子</th>
            <th>生产性能</th>
            <th>土地利用</th>
            <th class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in $store.state.speciesList" :key="item.soil_id">
            <td>{{ compress(item.soil_type_name) }}</td>
            <td>{{ compress(item.description) }}</td>
            <td>{{ compress(item.landform) }}</td>
            <td>{{ compress(item.area_hm) }}</td>
            <td>{{ compress(item.area_wanmu) }}</td>
            <td>{{ compress(item.parent) }}</td>
            <td>{{ compress(item.profile_pattern) }}</td>
            <td>{{ compress(item.effective_depth) }}</td>
            <td>{{ compress(item.main_characters) }}</td>
            <td>{{ compress(item.soil_constraint_factor) }}</td>
            <td>{{ compress(item.production_performance) }}</td>
            <td>{{ compress(item.landuse) }}</td>

            <td class="text-center">
              <router-link
                :to="{
                  path: '/soilspeciesedit',
                  query: { item: JSON.stringify(item) },
                }"
                >编辑</router-link
              ><span v-html="'\u00a0\u00a0\u00a0'"></span>
              <a class="delete" @click="handleClick(item.soil_id)">删除</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="loading">请选择土类及亚类</div>
    </div>
  </div>
</template>
<script>
import globalData from "@/global.js";
import axios from "axios";

export default {
  data() {
    return {
      classArr: globalData.classArr,
      selectClass: "",
      selectSubClass: "",
      resArr: [],
    };
  },
  mounted() {
    // console.log("mounted");

    // 土类
    if (this.$store.state.class == -1) {
      this.selectClass = this.classArr[0].group_id;
      this.$store.commit("classMutation", this.selectClass);
    } else {
      // console.log('使用缓存数据')
      this.selectClass = this.$store.state.class;
    }

    // 亚类
    if (this.$store.state.subClass != -1) {
      // console.log('使用缓存数据')
      this.selectSubClass = this.$store.state.subClass;
    }
    if (this.$store.state.subClassList.length === 0) {
      this.$store.dispatch("getSubClassListAction");
    } else {
      // console.log('使用缓存数据')
    }
  },
  updated() {
    // console.log("updated");
    // 土类
    if (this.$store.state.class != this.selectClass) {
      this.$store.commit("classMutation", this.selectClass);
      this.$store.dispatch("getSubClassListAction");
      this.selectSubClass = -1;
      this.$store.commit("subClassMutation", -1);
      this.$store.commit("speciesListMutation", []);
    } else {
      //   console.log("土类使用缓存数据");
      this.selectClass = this.$store.state.class;
    }

    // 亚类
    if (
      this.selectSubClass &&
      this.$store.state.subClass != this.selectSubClass
    ) {
      //   console.log("修改亚类");
      this.$store.commit("subClassMutation", this.selectSubClass);
      // 土种
      this.$store.dispatch("getSpeciesListAction");
    } else {
      //   console.log("亚类使用缓存数据");
      this.selectSubClass = this.$store.state.subClass;
    }
  },
  methods: {
    handleClick(soil_id) {
      //   console.log({ soil_id });
      this.$confirm("此操作将永久删除该条目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            .delete("/data_cultivate/delCultivateMessage", {
              params: { soil_id },
            })
            .then((res) => {
              console.log(res.data);
              this.$message({
                message: "删除成功",
                type: "success",
              });
              // 更新数据
              this.$store.dispatch("getSpeciesListAction");
            });
        })
        .catch(() => {
        //   this.$message.info("已取消删除");
        });
    },
    compress(data) {
      return data
        ? data.length > 10
          ? data.substring(0, 10) + "..."
          : data
        : "";
    },
  },
};
</script>

<style>
select {
  padding: 2px 1px;
}
</style>